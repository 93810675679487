import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth();
  // const currentUser = true;
  // console.log(currentUser);
  return (
    <Route
      {...rest}
      render={(props) => {
        // console.log("In PrivateRoute: ", props);
        return currentUser ? (
          !currentUser.emailVerified ? (
            <Component {...props} />
          ) : (
            <Redirect to="/login" />
          )
        ) : (
          <Redirect to="/login" />
          // <Component {...props} />
        );
      }}
    ></Route>
  );
}
