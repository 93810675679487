import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import routes from "./routes";
// import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import PrivateRoute from "./components/private_route/PrivateRoute";
import Login from "./components/acc/Login";
import AuthProvider from "./contexts/AuthContext";
import SignUp from "./components/acc/SignUp";
// import PrivateAdminRoute from "./components/private_route/PrivateAdminRoute";
// import Errors from "./views/Errors";
// import empty from "./layouts/empty";
// import { Switch } from "@material-ui/core";
// import ComponentsOverview from "./views/ComponentsOverview";

export default () => (
  <Router>
    <AuthProvider>
    <div>
      <Switch>

      {routes.map((route, index) => {
        return (
          // console.log("Print!")
          <PrivateRoute
            key={index}
            path={route.path}
            exact={route.exact}
            component={props => {
              // console.log(route.path);
              return (
                <route.layout {...props}>
                  <route.component {...props} />
                </route.layout>
              );
            }}

            
            // component={withTracker(props => {
            //   return (
            //     <route.layout {...props}>
            //       <route.component {...props} />
            //     </route.layout>
            //   );
            // })}
            // component={route.component}
          />

          // <Route
          //   key={index}
          //   path={route.path}
          //   exact={route.exact}
          //   component={props => {
          //     return (
          //       <route.layout {...props}>
          //         <route.component {...props} />
          //       </route.layout>
          //     );
          //   }}

            
          //   // component={withTracker(props => {
          //   //   return (
          //   //     <route.layout {...props}>
          //   //       <route.component {...props} />
          //   //     </route.layout>
          //   //   );
          //   // })}
          //   // component={route.component}
          // />
        );
      })}

      <Route 
          path="/login"
          component={Login}
      />
      
      <Route 
          path="/signup"
          component={SignUp}
      />

      </Switch>
    </div>
    </AuthProvider>
  </Router>
);
