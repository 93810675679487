import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  Button,
  ListGroup,
  ListGroupItem,
  // Progress
} from "shards-react";

import { useAuth } from "../../contexts/AuthContext";

// const UserDetails = ({ userDetails }) => (
function UserDetails({ userDetails }) {
  const { logout } = useAuth();
  const [userName, setUserName] = useState();
  const [org, setOrg] = useState();
  const [email, setEmail] = useState();
  const [logo, setLogo] = useState("images/logo/logo_bg.png");
  const [bio, setBio] = useState();

  async function handleLogout() {
    console.log("Clicked");
    await logout();
  }

  const user = JSON.parse(localStorage.getItem("User"));

  function fetchDetails() {
    if (user != null) {
      // console.log("User: " + user);
      setUserName(user["name"]);
      setOrg(user["org_name"]);
      setEmail(user["email"]);
      setLogo(user["logo"]);
      if (user["bio"] === "") {
        setBio("You do not have a Bio, This feature will be added soon.");
      } else {
        setBio(user["bio"]);
      }
  
    } else {
      setUserName("Your session has expired! Please logout and relogin.");
    }
  }

  useEffect(() => {
    fetchDetails();
  }, [])

  // console.log("User Details: ", JSON.parse(localStorage.getItem("User")));

  return(
    <Card small className="mb-4 pt-3">
      <CardHeader className="border-bottom text-center">
        <div className="mb-3 mx-auto" style={{ width: "100px", height: "100px", overflow: "hidden", borderRadius: "50%", boxShadow: "0px 10px 20px #4444"}}>
          <img
            className="rounded-circle"
            src={logo}
            alt={userName}
            // width="110"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
        <h4 className="mb-0">{userName}</h4>
        <span className="text-muted d-block mb-2">{email}</span>
        <span className="text-muted d-block mb-2">{org}</span>
        {/* <Button pill outline size="sm" className="mb-2">
          <i className="material-icons mr-1">person_add</i> Follow
        </Button> */}
        <Button pill outline size="sm" onClick={handleLogout} className="mb-2" style={{}}>
          <i className="material-icons mr-1">person_add</i> logout
        </Button>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="px-4">
          {/* <div className="progress-wrapper">
            <strong className="text-muted d-block mb-2">
              {userDetails.performanceReportTitle}
            </strong>
            <Progress
              className="progress-sm"
              value={userDetails.performanceReportValue}
            >
              <span className="progress-value">
                {userDetails.performanceReportValue}%
              </span>
            </Progress>
          </div> */}
        </ListGroupItem>
        <ListGroupItem className="p-4">
          <strong className="text-muted d-block mb-2">
            Bio
          </strong>
          <span>{bio}</span>
        </ListGroupItem>
      </ListGroup>
    </Card>
  )
// );
};

UserDetails.propTypes = {
  /**
   * The user details object.
   */
  userDetails: PropTypes.object
};

UserDetails.defaultProps = {
  userDetails: {
    name: "Leaflets",
    // avatar: require("images/avatars/0.jpg"),
    avatar: "images/logo/logo_bg.png",
    jobTitle: "Project Manager",
    performanceReportTitle: "Workload",
    performanceReportValue: 74,
    metaTitle: "Bio",
    metaValue:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio eaque, quidem, commodi soluta qui quae minima obcaecati quod dolorum sint alias, possimus illum assumenda eligendi cumque?"
  }
};

export default UserDetails;
