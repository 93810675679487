/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  // CardFooter,
  Badge,
  // Button
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import firebase from "../firebase";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    // padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing(),
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    // padding: theme.spacing.unit * 2,
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    // padding: theme.spacing.unit,
  },
}))(MuiDialogActions);


function BlogPosts() {
  const [blogs,setBlogs]=useState([]);
  // const [admin,setAdmin]=useState();
  const { currentUser } = useAuth();
  const history = useHistory();

  const [publish, setPublish] = useState();
  const [id, setId] = useState();
  const [index, setIndex] = useState();
  const [title, setTitle] = useState();
  const [desc, setDesc] = useState();
  const [img, setImg] = useState();
  const [pref, setPref] = useState([]);
  const [logo, setLogo] = useState([]);
  const [cat, setCat] = useState();
  const [ml, setMl] = useState();

  const [edTitle, setEdTitle] = useState("");
  const [edDesc, setEdDesc] = useState("");
  // const [edCat, setEdCat] = useState("");

  const [open, setOpen] = useState(false);

  function handleClickOpen() {
    setOpen(true);
  };

  function handleClose() {
    setOpen(false);
  };

  
  const db = firebase.firestore();

  var allArticles = [];
  // const response=db.collection('News_Test_collection');
  const response=db.collection('news');
  
  const fetchBlogs=async()=>{
    // const response=db.collection('news');
    const data=await response
    .where("ver", "==", false)
    .orderBy("pub_by")
    .get();
    data.docs.forEach(item=>{
    //  console.log("fetched!", item.data(), item.id);
     let i = item.data();
     i['id'] = item.id;
    //  console.log(i);
     allArticles.push(i);
    })
    setBlogs(allArticles);
    localStorage.setItem("articles", JSON.stringify(allArticles));
  }

  const isAdmin=async()=>{
    if (currentUser != null) {
      // console.log(currentUser);
      // currentUser['admin'] = true;
      currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        if (!!idTokenResult.claims.admin) {
            // setAdmin(true);
            fetchBlogs();
            // console.log("Admin");
        } else {
            // setAdmin(false);
            // console.log("Not Admin");
            history.push("/");
        }
      })
    }
  }

  function openAdminEditor(id, publish) {
    // console.log("Clicked to Open");
    // history.push("/admin-editor");
    var art = JSON.parse(localStorage.getItem("articles"));
    // console.log("This Article is: ", art[id]);
    !publish ? setPublish(false) : setPublish(true);
    setId(art[id]['id']);
    setIndex(id);
    setTitle(art[id]['title']);
    setImg(art[id]['img']);
    setDesc(art[id]['desc']);
    setPref(art[id]['pref']);
    setLogo(art[id]['pub_logo']);
    setCat(art[id]['cats']);
    setMl(art[id]['main_link']);
    handleClickOpen();
  }

  useEffect(() => {
    isAdmin();
  }, [])

  function saveDetails(ver, published) {
    // console.log("Changed Details: ", edTitle, edDesc);
    // let ref = db.collection('News_Test_collection');
    var data = {
      title: "",
      desc: ""
    }

    if (ver) {
      data['ver'] = true
    }

    if (edTitle === "" && edDesc === "") {
      data['desc'] = desc;
      data['title'] = title;
      // console.log("Both are empty");
    }
    if (edDesc === "" && edTitle !== "") {
      data['title'] = edTitle;
      data['desc'] = desc;
      // console.log("Empty Description!");
    }
    if (edTitle === "" && edDesc !== "") {
      data['title'] = title;
      data['desc'] = edDesc;
      // console.log("Empty title", edDesc);
    }
    if (edTitle !== "" && edDesc !== "") {
      data['title'] = edTitle;
      data['desc'] = edDesc;
      // console.log("Both are filled!");
    }

    if (!published) {
      response.doc(id).update({ver: false}).then(() => {
        // console.log("Done!");
        let item_update = JSON.parse(localStorage.getItem("articles"));
        if (!!item_update) {
          // console.log("Items");
          item_update[index]['ver'] = false;
          setBlogs(item_update);
          handleClose();
          localStorage.setItem("articles", JSON.stringify(item_update));
          // console.log(item_update);
        }
        return;
      });
    }

    // if (ver) {
      response.doc(id).update(data).then(() => {
        console.log("Done!");
        let item_update = JSON.parse(localStorage.getItem("articles"));
        if (!!item_update) {
          // console.log("Items");
          item_update[index]['title'] = data['title'];
          item_update[index]['desc'] = data['desc'];
          if (ver) {item_update[index]['ver'] = true; handleClose()};
          setBlogs(item_update);
          localStorage.setItem("articles", JSON.stringify(item_update));
          // console.log(item_update);
        }
      });
    // }

    // if (edTitle === "" && edDesc === "") {
    //   return;
    // }

    // console.log(data);
  }

    return (
      <Container fluid className="main-content-container px-4">
        {/* {
          // console.log("In Blog Posts", admin),
          // console.log("In Blog Posts", admin),
          useEffect(() => {
            fetchBlogs();
            isAdmin();
          }, []),
          // console.log("In Blog Posts", admin),
          currentUser.admin ? "" : <Redirect to="/" />
        } */}
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Blog Posts" subtitle="Components" className="text-sm-left" />
        </Row>

        {/* First Row of Posts */}
        <Row>
          { blogs && blogs.map( (blog, id) => {
          if (blog.ver === false)
          {
            return(
            <Col lg="3" md="6" sm="12" className="mb-4" key={blog.date}>
              <Card onClick={() => openAdminEditor(id, true)} small className="card-post card-post--1">
                <div
                  className="card-post__image"
                  // style={{ backgroundImage: `url('https://images.indianexpress.com/2021/05/Google_Reuters_F.jpg')` }}
                  style={{ backgroundImage: `url('${blog.img}')` }}
                >
                  <Badge
                    pill
                    // className={`card-post__category bg-${post.categoryTheme}`}
                  >
                    {/* {blog.cats[0]} */}
                  </Badge>
                  <div className="card-post__author d-flex">
                    <a
                      href="#"
                      className="card-post__author-avatar card-post__author-avatar--small"
                      style={{ backgroundImage: `url('${blog.pub_logo}')` }}
                    >
                      Written by post.author
                    </a>
                  </div>
                </div>
                <CardBody>
                  <h5 className="card-title">
                    <a href="#" className="text-fiord-blue">
                      {blog.title}
                    </a>
                  </h5>
                  <p className="card-text d-inline-block mb-3">{blog.desc.slice(0, 100)}..</p>
                  <span className="text-muted">{blog.date}</span>
                </CardBody>
              </Card>
            </Col>
          )
        } return "";
        })
          }
        </Row>

        {/* Recently Published */}
        <h5>Recently Published</h5> (If you refresh the page the data will get erased.) <br /> <br />
        <Row>
          { blogs && blogs.map( (blog, id) => {
          if (blog.ver === true)
          {
            return(
            <Col lg="3" md="6" sm="12" className="mb-4" key={blog.date}>
              <Card onClick={() => openAdminEditor(id, false)} small className="card-post card-post--1">
                <div
                  className="card-post__image"
                  // style={{ backgroundImage: `url('https://images.indianexpress.com/2021/05/Google_Reuters_F.jpg')` }}
                  style={{ backgroundImage: `url('${blog.img}')` }}
                >
                  <Badge
                    pill
                    // className={`card-post__category bg-${post.categoryTheme}`}
                  >
                    {/* {blog.cats[0]} */}
                  </Badge>
                  <div className="card-post__author d-flex">
                    <a
                      href="#"
                      className="card-post__author-avatar card-post__author-avatar--small"
                      style={{ backgroundImage: `url('${blog.pub_logo}')` }}
                    >
                      Written by post.author
                    </a>
                  </div>
                </div>
                <CardBody>
                  <h5 className="card-title">
                    <a href="#" className="text-fiord-blue">
                      {blog.title}..
                    </a>
                  </h5>
                  <p className="card-text d-inline-block mb-3">{blog.desc.slice(0, 100)}..</p>
                  <span className="text-muted">{blog.date}</span>
                </CardBody>
              </Card>
            </Col>
          )
        } return "";
        })
          }
        </Row>

        {/* Second Row of Posts */}
        {/* <Row>
          {PostsListTwo.map((post, idx) => (
            <Col lg="6" sm="12" className="mb-4" key={idx}>
              <Card small className="card-post card-post--aside card-post--1">
                <div
                  className="card-post__image"
                  style={{ backgroundImage: `url('${post.backgroundImage}')` }}
                >
                  <Badge
                    pill
                    className={`card-post__category bg-${post.categoryTheme}`}
                  >
                    {post.category}
                  </Badge>
                  <div className="card-post__author d-flex">
                    <a
                      href="#"
                      className="card-post__author-avatar card-post__author-avatar--small"
                      style={{ backgroundImage: `url('${post.authorAvatar}')` }}
                    >
                      Written by Anna Ken
                    </a>
                  </div>
                </div>
                <CardBody>
                  <h5 className="card-title">
                    <a className="text-fiord-blue" href="#">
                      {post.title}
                    </a>
                  </h5>
                  <p className="card-text d-inline-block mb-3">{post.body}</p>
                  <span className="text-muted">{post.date}</span>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row> */}

        <div>
          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth="md"
            scroll="paper"
            // fullScreen

          >
            <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ display: "flex", flexDirection: "column" }}>
              Editor
              
            </DialogTitle>

            <DialogContent>

              <div style={{ width: "80%", maxWidth: "460px", height:"auto", position: "relative", left: "50%", transform: "translateX(-50%)", overflow: "hidden", borderRadius: "10px", margin: "10px" }}>
                <img src={img} alt={title} style={{ width: "100%" }}/>
              </div>

              
                <Chip
                  avatar={<Avatar alt="Natacha" src={logo} />}
                  label=""
                  // onDelete={handleDelete}
                  // className={classes.chip}
                /> <br /> <br />

              Preferences <br />
              { pref && pref.map( (prefs, id) => {
                return (<Chip
                  label={prefs}
                  key={id}
                  // onClick={handleClick}
                  // onDelete={handleDelete}
                  // className={classes.chip}
                  // deleteIcon={<DoneIcon />
                  // }
                  style={{ margin: "4px" }}
                />)
              })
              }
              <br /> <br />

              Title <br />
              <Typography gutterBottom style={{ fontSize: "1.2em", opacity: "0.8" }}>
                {title}
              </Typography>
              <TextField
                autoFocus
                margin="dense"
                id="title"
                label="Edit Title"
                type="text"
                fullWidth
                variant="outlined"
                placeholder="New Title"
                onBlur={(e) => setEdTitle(e.target.value)}
                disabled={!publish}
              />
              <br /> <br />
              Category <br />
              <Chip
                  label={cat}
                  style={{ margin: "4px" }}
                />
              <br /> <br />

              Description <br />
              <Typography gutterBottom>
                {desc}
              </Typography>
              <TextField
                margin="dense"
                id="desc"
                label="Edit Description"
                multiline
                maxRows="8"
                minRows="6"
                type="text"
                fullWidth
                variant="outlined"
                placeholder="New Description"
                disabled={!publish}
                onBlur={(e) => setEdDesc(e.target.value)}
              />
              <br /> <br />

              Source link <br />
              <Typography gutterBottom>
                <a href={ml} target="_blank" rel="noopener noreferrer">{ml}</a>
              </Typography>

            </DialogContent>
            <DialogActions>
              <Button onClick={() => {saveDetails(false)}} disabled={!publish} color="primary">
                Save changes
              </Button>

              
              <Button onClick={() => {!publish ? saveDetails(false, false) : saveDetails(true, true)}} color="primary">
                {!publish ? "Unpublish" : "Publish"}
              </Button>
            </DialogActions>
          </Dialog>
        </div>



      </Container>
    );
}

export default BlogPosts;
