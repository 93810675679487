// import * as firebase from "firebase/app";
import firebase from "firebase/compat/app";
// require("firebase/auth");
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import "firebase/compat/firestore";
// // import { auth as authenticate } from "firebase/auth";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/analytics";


const firebaseConfig = {
    apiKey: "AIzaSyBBCQNGdqOqm97L2rVJDRlJ_PkMtUFX5ic",
    authDomain: "leaflets-6b146.firebaseapp.com",
    databaseURL: "https://leaflets-6b146-default-rtdb.firebaseio.com",
    projectId: "leaflets-6b146",
    storageBucket: "leaflets-6b146.appspot.com",
    messagingSenderId: "202878123015",
    appId: "1:202878123015:web:653b546075c79e55fafea2",
    measurementId: "G-NV5Z9N63ZN"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyADbNkN44KYCWDAO20_oNoxOgF_4vdAADI",
//   authDomain: "test-885bc.firebaseapp.com",
//   databaseURL: "https://test-885bc-default-rtdb.firebaseio.com",
//   projectId: "test-885bc",
//   storageBucket: "test-885bc.appspot.com",
//   messagingSenderId: "925876952209",
//   appId: "1:925876952209:web:a2f0fe4a576233542ff330",
//   measurementId: "G-WTK0PTXNBJ"
// };

const provider = new firebase.auth.GoogleAuthProvider();
// const provider = new authenticate.GoogleAuthProvider();
const app = firebase.initializeApp(firebaseConfig);
// const app = initializeApp(firebaseConfig);

export const signInWithGoogle = () => {
  return auth.signInWithPopup(provider);
};
// export const storage = firebase.storage();
// export const storage = storage();
export const auth = app.auth();
// export const firebaseAnalytics = firebase.analytics();
// export const firebaseAnalytics = getAnalytics(app);
export default firebase;
