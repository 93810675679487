// import React from 'react'
// // import { Link, useNavigate, useLocation } from "react-router-dom";
// import { Link, useHistory } from 'react-router-dom';
// import { useAuth } from "../../contexts/AuthContext";

// export default function SignUp() {
//     const { signup, logout } = useAuth();
//     const history = useHistory();

//     async function handleSignup(e) {
//         console.log("Login Button Pressed!");
//         e.preventDefault();
//         try {
//         await signup("yo1@gmail.com", "123456")
//         .then(console.log("Done"));
//         // .then(localStorage.setItem("isEmployer", employer));

//         history.push("/");
//         } catch (err) {
//         if (
//             err.message ===
//             "There is no user record corresponding to this identifier. The user may have been deleted."
//         ) {
//             console.log("Wrong Email");
//         } else console.log(err.message);
//         }
//     }
//     async function handleLogout() {
//         console.log("Clicked");
//         await logout();
//     }
//     return (
//         <div>
//             <div>
//                 <h1>Sign Up Now</h1>
//                 <input type="text" id="email" placeholder="email"></input>
//                 <input type="password" id="pass" placeholder="Password"></input>
//                 <button onClick={handleSignup}>Sign Up</button>
//                 <button onClick={handleLogout}>Logout</button>
//                 Have an account? <Link to="login"> Login</Link>
//             </div>
//         </div>

        
//     )
// }


import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
// import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

import { Link, useHistory } from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";
import firebase from "../../firebase";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <a color="inherit" target="_blank" rel="noopener noreferrer" href="https://leaflets.page/">
        Leaflets
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const { signup } = useAuth();
  const history = useHistory();
  const [errMsg, setErrMsg] = useState("");

  const [OrgName, setOrgName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState([]);
  const [conPass, setConPass] = useState("");
  
  const [logoFile, setLogoFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(false);

  const classes = useStyles();
  
  const db = firebase.firestore();
  const storage = firebase.storage();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };


  // Authentication
  async function handleSignup(e) {
    console.log("Login Button Pressed!");
    e.preventDefault();
    try {
      var user = await signup(email, pass)
      .then(console.log("Done"));

      return user;
    } catch (err) {
    if (
        err.message ===
        "Firebase: The email address is badly formatted. (auth/invalid-email)."
    ) {
        console.log("Wrong Email");
        setLoading(false);
        setOpen(true);
        setErrMsg("This email is not valid!");
        return "This email is not valid!";
    } else 
    {
      // console.log(err.message);
      setLoading(false);
      setOpen(true);
      setErrMsg(err.message);
      return "error";
    }
    }
  }

  // Store file to the state.
  function handleLogoFileChange(e) {
    if (e.target.files[0] == null) {
      console.log("There is no file! ");
      alert("Please add a logo to upload");
      return;
    }
    setLogoFile(e.target.files[0]);
    setOpen(true);
    setErrMsg(`Added logo ( ${e.target.files[0].name} )!`);
    // console.log("Name of the File: ", e.target.files[0]);
  }

  // Upload the logo
  async function handleFileUpload(e) {
    e.preventDefault();
    let dt = new Date().getTime();
    // setDate(dt);
    setLoading(true);

    if (logoFile == null) {
      // console.error(`not an image, the image file is a ${typeof(logoFile)}`);
      // alert("Please Upload an Image!");
      setOpen(true);
      setErrMsg("Please add a logo to upload!");
      setLoading(false);
      return;
    }
    if (pass !== conPass) {
      setOpen(true);
      setErrMsg("Passwords do not match");
      setLoading(false);
      return;
    }
    if (firstName==="" || lastName==="" || OrgName==="" || email==="" || pass==="" || conPass==="" || pass !== conPass) {
      console.log(
        `%c Enter all Values`,
        "background: #f00; color: #fff; padding: 4px; border-radius: 4px;"
      );
      setOpen(true);
      setErrMsg("Please fill all the required fields");
      setLoading(false);
      return;
    }

    // User SignUp
    var newUser = await handleSignup(e);
    // console.log("New User: ", newUser);
    if (newUser === "This email is not valid!") {
      return;
    }
    if (newUser === "error") {
      setLoading(false);
      setOpen(true);
      setErrMsg("Ugh.. snap an error occured! check your email, 'strong' password and try again!");
      return;
    }
    var uid = await newUser['user']['uid'];
    // console.log("UID: ", uid);

    // console.log("Image Upload Start");
    const ref = storage.ref(`/company/${email}/${uid}_${logoFile.name}`);
    const uploadTask = ref.put(logoFile);

    uploadTask.on('state_changed',
    (snapShot) => {
      setLoading(true);
    }, (err) => {
      console.log(err);
      setOpen(true);
      setErrMsg("The logo didn't upload");
      setLoading(false);
    }, () => {
      storage.ref(`company/${email}/`).child(`${uid}_${logoFile.name}`).getDownloadURL()
      .then((fileUrl) => {
        console.log(fileUrl);
        createUserData(dt, fileUrl, uid);
      })
    }
    )
  }

  // fetch details and upload to firestore
  async function createUserData(date_new, imgUrl, uid) {
    if (pass !== conPass) {
      setLoading(false);
      return;
    }
    if (firstName==="" || lastName==="" || OrgName==="" || email==="" || pass==="" || conPass==="" || imgUrl==="") {
      console.log(
        `%c Enter all Values`,
        "background: #f00; color: #fff; padding: 4px; border-radius: 4px;"
      );
      setLoading(false);
      return;
    }
    let data = {
      uid: uid,
      name: firstName + " " + lastName,
      org_name: OrgName,
      author: "",
      bio: "",
      email: email,
      logo: imgUrl,
      date: date_new,
      isAdmin: false,
    }
    // console.log("Data before Push: ", data);
    addData(data);
  }

  // Add Data to firestore
  function addData(data) {
    // console.log("addData function is called!");
    db.collection("company")
    .doc(data["uid"])
    .set(data)
    .then(function () {
      console.log(
        "%c User Craeted Successfully",
        "background: #fff; color: #00963e; padding: 4px; border-radius: 4px;"
      );
      setOrgName("");
      setEmail("");
      setPass("");
      setConPass("");
      setLogoFile(null);
      setLoading(false);
      console.clear();
      history.push("/");
    })
    .catch(function (e) {
      console.log(
        `%c Some error occured ${e}`,
        "background: #f00; color: #fff; padding: 4px; border-radius: 4px;"
      );
      setLoading(false);
    });
  }


  return (
    <Container component="main" maxWidth="xs">

      {errMsg && (
        <Snackbar
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
          }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message={ errMsg }
          action={
          <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
              </IconButton>
          </React.Fragment>
        }>
        </Snackbar>
      )}


      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form onSubmit={handleFileUpload} className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                onBlur={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                onBlur={(e) => setLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="orgName"
                label="Organisation Name"
                name="orgName"
                onBlur={(e) => setOrgName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Organisation Email Address"
                name="email"
                autoComplete="email"
                type="email"
                onBlur={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onBlur={(e) => setPass(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="conPass"
                label="Confirm Password"
                type="password"
                id="conPass"
                onBlur={(e) => setConPass(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              
                <input
                    accept="image/*"
                    className={classes.input}
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    type="file"
                    onChange={handleLogoFileChange}
                />
                <label htmlFor="raised-button-file">
                    <Button component="span" color="primary" className={classes.button} startIcon={<PhotoCamera />}>
                        Upload Your Logo
                    </Button>
                </label>
            </Grid>
            {/* <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid> */}
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link to="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}