import React, { useState, useEffect } from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../../flux";
import { useAuth } from "../../../contexts/AuthContext";

// class SidebarNavItems extends React.Component {
function SidebarNavItems() {
  const { currentUser } = useAuth();
  // constructor(props) {
  //   super(props)

  //   this.state = {
  //     navItems: Store.getSidebarItems()
  //   };

  //   this.onChange = this.onChange.bind(this);
  // }

  const [items, setItems] = useState([]);
  const [admin, setAdmin] = useState(false);


  // function componentWillMount() {
  //   Store.addChangeListener(this.onChange);
  // }

  // function componentWillUnmount() {
  //   Store.removeChangeListener(this.onChange);
  // }

  // function onChange() {
  //   setItems(Store.getSidebarItems());
  //   // this.setState({
  //   //   ...this.state,
  //   //   navItems: Store.getSidebarItems()
  //   // });
  // }

  
  useEffect(() => {
    isAdmin();
    console.log(Store.getSidebarItems());
    setItems(Store.getSidebarItems());
  }, [])

  

  // render() {
    // const { navItems: items } = this.state;
    const posts = 
      {
        title: "Blog Posts",
        htmlBefore: '<i class="material-icons">vertical_split</i>',
        to: "/blog-posts",
      };

    const isAdmin=async()=>{
      if (currentUser != null) {
        console.log(currentUser);
        // currentUser['admin'] = true;
        currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          if (!!idTokenResult.claims.admin) {
              // setAdmin(true);
              // fetchBlogs();
              // console.log("In Side Nav Bar: ", true);
              setAdmin(true);
              return true;
            } else {
              // setAdmin(false);
              // console.log("In Side Nav Bar: ", false);
              setAdmin(false);
              return false;
          }
        })
      }
    }
    
    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {items.map((item, idx) => (
            <SidebarNavItem key={idx} item={item} />
            ))}
            {
              admin ? <SidebarNavItem key={10} item={posts} /> : ""
            }

        </Nav>
      </div>
    )
  // }
}

export default SidebarNavItems;
