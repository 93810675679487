import React, { createContext, useContext, useState, useEffect } from "react";
import { auth } from "../firebase";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [isAdmin, setIsAdmin] = useState();
  const [loading, setLoading] = useState(true);

  // const db = firebase.firestore();
  // const ref = db.collection("company"); 

  function signup(email, password) {
    return auth
      .createUserWithEmailAndPassword(email, password)
      .then(localStorage.clear());
  }

  function login(email, password) {
    let promise = new Promise(function (resolve, reject) {
      auth
        .signInWithEmailAndPassword(email, password)
        .then((ref) => {
          resolve(ref);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return promise;
  }

  function logout() {
    return auth
      .signOut()
      .then(
        // console.log("Logged Out"),
        localStorage.clear(),
        // localStorage.removeItem("user"),
        // localStorage.removeItem("isEmployer")
      );
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  // function isSignedin() {

  // }

  // async function getuserData(uid) {
  //   var admin = false;
  //   await ref.doc(uid).get()
  //   .then(doc => {
  //       let data = doc.data();
  //       console.log(doc.data());
  //       // localStorage.setItem("User", JSON.stringify(data));
  //       console.log("isAdmin: []", doc.data()['isAdmin']);
  //       admin = doc.data()['isAdmin'];
  //       // console.log(JSON.parse(JSON.stringify(data)));
  //       return admin;
  //     });
  // }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      // user["admin"] = true;
      // var data = JSON.parse(user);
      // if (typeof user['admin'] == 'undefined') {
      //   user["admin"] = getuserData(user.uid);
      // } else {
      //   var ac = "";
      // }
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, [currentUser]);

  // This part is to check isAdmin
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user !== null) {
        await user.getIdTokenResult()
        .then((idTokenResult) => {
        if (!!idTokenResult.claims.admin) {
            // console.log("isAdmin in AuthContext");
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
        })
        .catch((e) => {
            setIsAdmin(false);
        });
    } else {
        setIsAdmin(false);
    }
    });

    return unsubscribe;
  }, [isAdmin]);

  // function checkAdmin() {
  //   if (currentUser !== null) {
  //     currentUser.getIdTokenResult()
  //     .then((idTokenResult) => {
  //     if (!!idTokenResult.claims.admin) {
  //         console.log("isAdmin in AuthContext");
  //         return true;
  //     } else {
  //         return false;
  //     }
  //     })
  //     .catch((e) => {
  //         return false;
  //     });
  //   } else {
  //       return false
  //   }
  // }

  const value = {
    currentUser,
    isAdmin,
    // checkAdmin,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
