
import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import {
  Card,
  CardBody,
  Row,
  Col,
  Form,
  FormInput,
  FormSelect,
  FormTextarea,
  // Modal,
  Button
} from "shards-react";
import firebase from "../firebase";
import { useHistory } from "react-router-dom";

import "react-quill/dist/quill.snow.css";


function BlogAdminEditor(){
  const [title, settitle] = useState("");
  const [cat, setCat] = useState("");
  const [pref, setPref] = useState([]);
  const [desc, setDesc] = useState("");
  const [source, setSource] = useState("");
  const [mainLink, setMainLink] = useState("");

  const [pubLogo, setPubLogo] = useState("Add the logo url!"); // Not required
  const { currentUser } = useAuth();
  
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  
  const getValue = (event) => {
    let arr = [];
    for (var option of event.target.options) {
      if (option.selected) {
        arr.push(option.value);
      }
    }
    setPref(arr);
  }

  const db = firebase.firestore();
  const storage = firebase.storage();

  function handleFileChange(e) {
    if (e.target.files[0] == null) {
      console.log("There is no file! ");
      alert("Please add an image to upload");
      return;
    }
    setFile(e.target.files[0]);
  }

  function handleFileUpload(e) {
    e.preventDefault();
    let dt = new Date().getTime();
    // setDate(dt);

    if (file == null) {
      console.error(`not an image, the image file is a ${typeof(file)}`);
      alert("Please Upload an Image!");
      return;
    }
    if (title==="" || cat==="" || pref.length===0 || desc==="" || source==="" || mainLink==="") {
      alert("Please enter all the details correctly!"); // Adding a modal window could be better
      console.log(
        `%c Enter all Values`,
        "background: #f00; color: #fff; padding: 4px; border-radius: 4px;"
      );
      return;
    }

    console.log("Image Upload Start");
    const ref = storage.ref(`/news/${dt}_${file.name}`);
    const uploadTask = ref.put(file);

    uploadTask.on('state_changed',
    (snapShot) => {
      setLoading(true);
      // console.log(snapShot);
    }, (err) => {
      console.log(err);
    }, () => {
      storage.ref('news').child(`${dt}_${file.name}`).getDownloadURL()
      .then((fileUrl) => {
        // setFile(null);
        // console.log(fileUrl);
        uploadArticle(dt, fileUrl);
      })
    }
    )
  }

  async function uploadArticle(date_new, imgUrl) {
    // let dt = new Date().getTime();
    // console.log(date_new, imgUrl);
    if (title==="" || cat==="" || pref.length===0 || desc==="" || source==="" || mainLink==="" || imgUrl==="") {
      alert("Please enter all the details correctly!"); // Adding a modal window could be better
      console.log(
        `%c Enter all Values`,
        "background: #f00; color: #fff; padding: 4px; border-radius: 4px;"
      );
      return;
    }
    let data = {
      title: title,
      cats: cat,
      pref: pref,
      desc: desc,
      main_link: mainLink,
      img: imgUrl,
      // pub_by: currentUser.uid,
      pub_date: source,
      date: date_new,
      pub_logo: pubLogo,
      ver: false,
    }
    // console.log("Data before Push: ", data);
    addData(data);
  }

  function addData(data) {
    // console.log("addData function is called!");
    db.collection("News_Test_collection")
    .add(data)
    .then(function () {
      console.log(
        "%c Article Uploaded Succesfully",
        "background: #fff; color: #00963e; padding: 4px; border-radius: 4px;"
      );
      settitle("");
      setCat("");
      setPref([]);
      setDesc("");
      setMainLink("");
      setSource("");
      setLoading(false);
      setPubLogo("Add the logo url!");
      document.getElementById("artForm").reset();
      setLoading(false);
    })
    .catch(function (e) {
      console.log(
        `%c Article not Uploaded ${e}`,
        "background: #f00; color: #fff; padding: 4px; border-radius: 4px;"
      );
    });
  }

  
  const isAdmin=async()=>{
    if (currentUser != null) {
      console.log(currentUser);
      // currentUser['admin'] = true;
      currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        if (!!idTokenResult.claims.admin) {
            // setAdmin(true);
            // fetchBlogs();
            console.log("Admin");
        } else {
            // setAdmin(false);
            console.log("Not Admin");
            history.push("/");
        }
      })
    }
  }

  useEffect(() => {
    isAdmin();
  }, [])

  return(
    <Card small className="mb-3">
      <CardBody>
        <Form id="artForm" className="add-new-post" onSubmit={handleFileUpload}>
        {/* <div className="add-new-post"> */}
          
          <label htmlFor="title">Title</label>
          <FormInput required size="lg" id="title" className="mb-3" placeholder="Your Post Title" onBlur={(e) => settitle(e.target.value)}/>
          
          <div style={{ marginBottom: "0px" }}>
            <label htmlFor="feInputState">Categories</label>
            <FormSelect className="mb-3" required id="feInputState" onBlur={(e) => setCat(e.target.value)}>
              <option value="" disabled></option>
              <option value="Startups" >Startups</option>
              <option value="Business" >Business</option>
              <option value="Opinion Pieces" >Opinion Pieces</option>
              <option value="Individual Stories" >Individual Stories</option>
              <option value="Trivia" >Trivia</option>
            </FormSelect>
          </div>

          {/* Preferences */}
          <div style={{ marginBottom: "14px" }}>
            <label htmlFor="pref">Preferences</label>
            <FormSelect className="mb-3" onBlur={getValue} multiple required id="pref">
              <option value="" disabled>Press ctrl (Control) key to select multiple</option>
              <option value="Environment Daily" >Environment Daily</option>
              <option value="Nature and Bytes" >Nature and Bytes</option>
              <option value="Media and Content" >Media and Content</option>
              <option value="Environment Projects" >Environment Projects</option>
              <option value="Institutional Work" >Institutional Work</option>
              <option value="Think Tanks" >Think Tanks</option>
              <option value="Report and Analysis" >Report and Analysis</option>
              <option value="Events and Festivals">Events and Festivals</option>
              <option value="Tech and Research">Tech and Research</option>
            </FormSelect>
          </div>

          {/* Description */}
          <Row form>
            <Col md="12" className="form-group">
              <label htmlFor="feDescription">Description</label>
              <FormTextarea onBlur={(e) => setDesc(e.target.value)} required id="feDescription" rows="5" />
            </Col>
          </Row>

          {/* Source */}
          <label htmlFor="source">Source</label>
          <FormInput onBlur={(e) => setSource(e.target.value)} required size="lg" id="source" className="mb-3" placeholder="Source of the Article" />

          {/* full srticle link */}
          <label htmlFor="artLink">Full Article Link</label>
          <FormInput onBlur={(e) => setMainLink(e.target.value)} required size="lg" id="artLink" className="mb-3" placeholder="Link to Full Article" />

          {/* Image Upload */}
          <label htmlFor="uploadPhoto">Upload a Photo</label> <br/>
          <input onChange={handleFileChange} type='file' id="uploadPhoto" size="lg" className="mb-3" required placeholder="Add an image"/>

          {/* <Button type="submit" onClick={uploadArticle}>Upload</Button> */}
          
          {/* <Button theme="accent" size="sm" className="ml-auto" onClick={uploadArticle}>
            <i className="material-icons">file_copy</i> Publish
          </Button> */}

          <br />
          <div style= {{ display: "flex", alignItems: "right", justifyContent: "space-around" }}> 
            <Button theme="accent" size="sm" className="ml-auto" disabled={loading} style={{ marginRight: "10px" }}>
              <i className="material-icons">file_copy</i> Update
            </Button>

            <Button theme="accent" size="sm" className="ml-auto" disabled={loading}>
              <i className="material-icons">file_copy</i> Publish
            </Button>
          </div>
        {/* </div> */}
        </Form>
      </CardBody>
    </Card>
  )
}
// );
// Tittle (Short Simple text)
// Categories (Select One)
// Preferences (Select Multiple)
// Description (Long Simple text)
// Source (Short Simple text)
// Full Article Link (Url)
// Image (Url or IMage upload)
// Date (Auto timestamp)

export default BlogAdminEditor;
