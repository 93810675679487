import React, { useState} from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Dropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // Collapse,
  NavItem,
  // NavLink
} from "shards-react";

import { useAuth } from "../../../../contexts/AuthContext";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export default function UserActions(props) {
  const [visible, setVisible] = useState(false);
  const { logout } = useAuth();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function toggleUserActions() {
    setVisible(!visible);
  }

  async function handleLogout() {
    console.log("Clicked!");
    await logout();
    history.push("/");
  }

  var stringData = localStorage.getItem("User")
  var logoURL = require("./../../../../images/logo/logo_bg.png");

  if (stringData === null ){
    logoURL = require("./../../../../images/logo/logo_bg.png");
  }else {
    logoURL = JSON.parse(stringData)['logo'];
  }

  return (
    <NavItem tag={Dropdown} caret toggle={toggleUserActions}>

      <div style={{ display: "flex", height: "100%", alignItems: "center", justifyContent: "space-evenly" }}>
        <div onClick={handleClick} style={{ height: "100%", display: "flex", marginLeft: "10px", alignItems: "center" }}>
          <div style={{ width: "40px", height: "40px", overflow: "hidden", borderRadius: "50%" }}>
            <img
              src={logoURL}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              alt="My Profile Menu"
            />
          </div>
        </div>
        <Button
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{ width: "100px", height: "100%" }}
        >
          Menu
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <Link to="/user-profile-lite"><MenuItem onClick={handleClose}> My Profile </MenuItem></Link>
          {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
    </NavItem>
  );
}
