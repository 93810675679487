import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
// import MuiAlert from '@material-ui/core/Alert';

import { Link, useHistory, Redirect } from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";
import firebase from "../../firebase";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <a color="inherit" target="_blank" rel="noopener noreferrer" href="https://leaflets.page/">
        Leaflets
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// function Alert(props) {
    // return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const { currentUser, login } = useAuth();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  // const [uid, setUid] = useState("");

  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(false);
  
  const db = firebase.firestore();
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  // if (currentUser) {
  //   console.log("You are already logged in");
  //   <Redirect to="/" />
  // }

  async function handleLogin(e) {
      e.preventDefault();
      setLoading(true);
      setErrMsg("");
      console.log("Login Button Pressed!");
      try {
        // .then(localStorage.setItem("isEmployer", employer));
        await login(email, pass)
        .then(function (crrUser) {
            console.log("Done");
            console.log("Current User: ", crrUser);
            crrUser.user.getIdTokenResult()
            .then((idTokenResult) => {
              if (!!idTokenResult.claims.admin) {
                console.log("He is an admin buddy");
                console.log(idTokenResult.claims.admin);
              } else {
                console.log(idTokenResult.claims);
              }
            });
            var uid = crrUser['user']['uid'];
            console.log("Current User: ", uid);
            // setUid(crrUser['user']['uid']);
            // console.clear();
            // call the fetching function here
            fetchUserData(uid);
            setLoading(false);
        });
      } catch (er) {
      if (
          er.message ===
          "Firebase: There is no user record corresponding to this identifier. The user may have been deleted. (auth/user-not-found)."
      ) {
          console.log("Wrong Email");
          setErrMsg("Didn't find your Email!🤔");
          setOpen(true);
          setLoading(false);
      } 
      else if (er.message === "Firebase: The password is invalid or the user does not have a password. (auth/wrong-password).") 
      {
          console.log("Password not correct!");
          setErrMsg("Password not correct!");
          setOpen(true);
          setLoading(false);
      } 
      else if (er.message === "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).") 
      {
          console.log("Access to this account has been temporarily disabled due to many failed login attempts");
          setErrMsg("Access to this account has been temporarily disabled due to many failed login attempts");
          setOpen(true);
          setLoading(false);
      } 
      else {
          console.log(er.message);
          console.log(er);
          setErrMsg(er.message);
          setOpen(true);
          setLoading(false);
        };
      }
  }

  async function fetchUserData(uid) {
    await db.collection("company")
    .doc(uid)
    .get()
    .then(doc => {
        let data = doc.data();
        console.log(doc.data());
        localStorage.setItem("User", JSON.stringify(data));
        // console.log(JSON.parse(JSON.stringify(data)));
        history.push("/");
      }
    );

    // console.log("User Data: ", userData);
  }

  return (
    <Container component="main" maxWidth="xs">

      {
        currentUser ? <Redirect to="/" /> : ""
      }

    {errMsg && (
        <Snackbar
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message={ errMsg }
            action={
            <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
                </IconButton>
            </React.Fragment>
            }>
            {/* <Alert onClose={handleClose} severity="danger">
                { errMsg }
            </Alert> */}
        </Snackbar>
    )}

      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form onSubmit={handleLogin} className={classes.form} method="POST">
          <TextField
            variant="outlined"
            margin="normal"
            required={true}
            fullWidth
            onBlur={(e) => {setEmail(e.target.value)}}
            id="email"
            label="Email Address"
            name="email"
            type="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required={true}
            fullWidth
            name="password"
            // ref={pass}
            onBlur={(e) => {setPass(e.target.value)}}
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            disabled={loading}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link to="signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
















// import React, { useRef, useState, useEffect } from "react";
// import { useAuth } from "../contexts/AuthContext";
// import { Link, useHistory, useLocation } from "react-router-dom";
// // import { signInWithGoogle } from "../firebase";
// // import IconButton from "@material-ui/core/IconButton";
// // import firebase from "../firebase";
// // import { useMediaQuery } from "./hooks";
// import Modal from "react-modal";

// export default function Login() {
//   const emailRef = useRef();
//   const passRef = useRef();
//   const { login, logout } = useAuth();
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [employer, setEmployer] = useState(null);
//   const [email, setEmail] = useState(null);
//   const [user, setUser] = useState(null);
//   const [viewPass, setViewPass] = useState(false);
//   const [loggedin, setLoggedIn] = useState(false);
//   const history = useHistory();
//   const location = useLocation();
//   let isMobile = useMediaQuery("(max-width: 992px)");
//   let [modalOpen, setModalIsOpen] = useState(true);

//   let emailVerified, fromSignup;

//   if (location.state !== undefined) {
//     // emailVerified = location.state.emailVerified;
//     fromSignup = location.state.fromSignUp;
//   }
//   console.log(fromSignup);
//   async function userEmp(event) {
//     setError("");
//     try {
//       if (!employer) {
//         await firebase
//           .firestore()
//           .collection("usersT")
//           .where("email", "==", email)
//           .get()
//           .then((QuerySnapshot) => {
//             if (QuerySnapshot.empty) {
//               console.log(
//                 "%c I feel so empty",
//                 "background: #fff; color: #000"
//               );
//               setError(
//                 "The user doesn't exist. Please check your credentials."
//               );
//               setLoggedIn(false);
//             } else {
//               console.log(
//                 `%c I found you ${email}`,
//                 "background: #fff; color: #000"
//               );
//               QuerySnapshot.forEach((doc) => {
//                 if (doc.data().isOrg) {
//                   setError("Wrong user type selected.");
//                   setLoggedIn(false);
//                 } else {
//                   console.log(doc.data());
//                   localStorage.setItem(
//                     "user",
//                     JSON.stringify({ ...doc.data(), is_org: false })
//                   );
//                   handleLogin(event);
//                 }
//               });
//             }
//           })
//           .catch((e) => {
//             setError(e.message);
//           });
//       } else {
//         await firebase
//           .firestore()
//           .collection("organizationsT")
//           .where("org_email", "==", email)
//           .get()
//           .then((QuerySnapshot) => {
//             if (QuerySnapshot.empty) {
//               console.log(
//                 "%c I feel so empty",
//                 "background: #fff; color: #000"
//               );
//               setError(
//                 "The organisation doesn't exist in our database. Please check your credentials."
//               );
//               setLoggedIn(false);
//             } else {
//               console.log(
//                 `%c I found you ${email}`,
//                 "background: #fff; color: #000"
//               );
//               QuerySnapshot.forEach((doc) => {
//                 if (!doc.data().isOrg) {
//                   setError("Wrong user type selected.");
//                   setLoggedIn(false);
//                 } else {
//                   console.log(doc.data());
//                   localStorage.setItem(
//                     "user",
//                     JSON.stringify({ ...doc.data(), is_org: true })
//                   );
//                   handleLogin(event);
//                 }
//               });
//             }
//           })
//           .catch((e) => {
//             setError(e.message);
//           });
//       }
//     } catch (err) {
//       if (
//         err.message ===
//         "Function CollectionReference.doc() cannot be called with an empty path."
//       )
//         setError("Credentials cannot be empty");
//       setLoggedIn(false);
//     }
//   }

//   useEffect(() => {
//     if (employer === true || employer === false) userEmp();
//   }, []);

//   async function handleSubmit(e) {
//     e.preventDefault();
//     if (employer != null) {
//       setLoggedIn(true);
//       userEmp(e);
//     } else {
//       setError("User type selection cannot be empty");
//     }
//   }
//   async function handleLogin(e) {
//     e.preventDefault();
//     try {
//       setError("");
//       setLoading(true);
//       await login(email, passRef.current.value).then(
//         localStorage.setItem("isEmployer", employer)
//       );
//       history.push("/profile");
//     } catch (err) {
//       if (
//         err.message ===
//         "There is no user record corresponding to this identifier. The user may have been deleted."
//       ) {
//         setError("Wrong email id or password. Please check again");
//       } else setError(err.message);
//     }
//     setLoggedIn(false);
//     setLoading(false);
//   }
//   useEffect(() => {
//     console.log(error);
//   }, [error]);

//   const handleError = () => {
//     setError(null);
//   };
//   return (
//     <div
//       class="container"
//       id="login"
//       tabIndex="-1"
//       aria-labelledby="registermodal"
//       aria-hidden="true"
//       style={{ width: "100%" }}
//     >
//       {error && (
//         <Modal
//           isOpen={true}
//           closeTimeoutMS={500}
//           className="hire-modal"
//           overlayClassName="Overlay"
//           contentLabel="Error Modal"
//         >
//           <button className="btn btn-light btn-sm" onClick={handleError}>
//             <i class="fa fa-close"></i>
//           </button>
//           <h2>Error !</h2>
//           <p>{error}</p>
//         </Modal>
//       )}
//       {fromSignup && (
//         <Modal
//           isOpen={modalOpen}
//           closeTimeoutMS={500}
//           className="hire-modal"
//           overlayClassName="Overlay"
//           contentLabel="Error Modal"
//         >
//           <button
//             className="btn btn-light btn-sm"
//             onClick={() => setModalIsOpen(false)}
//           >
//             <i class="fa fa-close"></i>
//           </button>
//           <h2>Yay! Just one step more</h2>
//           <hr />
//           <p>
//             You're just one step further from logging in. Kindly verify your
//             email before logging in again.
//           </p>
//         </Modal>
//       )}
//       <div className="row justify-content-md-center">
//         <div
//           class="col col-md-5 col-sm-12"
//           role="document"
//           style={{
//             marginTop: isMobile ? "0" : "5rem",
//           }}
//         >
//           <div
//             class="modal-content"
//             id="registermodal"
//             style={{
//               boxShadow: "0 0 1em 0 rgba(0, 0, 0, 0.3)",
//               border: "none",
//               borderRadius: "12px",
//             }}
//           >
//             <div class="modal-header">
//               <h4 align="center">Sign In</h4>
//             </div>
//             <div class="modal-body">
//               <div class="login-form">
//                 <form onSubmit={handleSubmit}>
//                   <div class="form-group">
//                     <label>User Type</label>
//                     <div class="row">
//                       <div class="col-6">
//                         <div class="form-group mb-1">
//                           <input
//                             id="e3"
//                             class="radio-custom"
//                             name="jtype"
//                             onClick={() => setEmployer(false)}
//                             type="radio"
//                             value="employee"
//                           />
//                           <label for="e3" class="radio-custom-label">
//                             Employee
//                           </label>
//                         </div>
//                       </div>
//                       <div class="col-6">
//                         <div class="form-group mb-1">
//                           <input
//                             id="e2"
//                             class="radio-custom"
//                             name="jtype"
//                             type="radio"
//                             value="employer"
//                             onClick={() => setEmployer(true)}
//                           />
//                           <label for="e2" class="radio-custom-label">
//                             Employer
//                           </label>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   {employer ? (
//                     <div class="form-group">
//                       <label htmlFor="email">Organisation email Id</label>
//                       <input
//                         id="email"
//                         type="email"
//                         name="email"
//                         class="form-control"
//                         placeholder="Organisation email Id"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)}
//                         aria-describedby="info"
//                       />
//                       <p id="info" style={{ fontSize: "1em" }}>
//                         Please use your organisation's registered email id
//                       </p>
//                     </div>
//                   ) : (
//                     <div class="form-group">
//                       <label htmlFor="email">Email Id</label>
//                       <input
//                         id="email"
//                         name="email"
//                         type="email"
//                         class="form-control"
//                         placeholder="Email Id"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)}
//                       />
//                     </div>
//                   )}

//                   <div class="form-group">
//                     <label htmlFor="password">Password</label>
//                     <input
//                       id="password"
//                       name="password"
//                       type={viewPass ? "text" : "password"}
//                       class="form-control"
//                       placeholder="Password"
//                       ref={passRef}
//                     ></input>
//                   </div>
//                   <div class="form-group">
//                     <input
//                       type="checkbox"
//                       className="checkbox-custom"
//                       id="view-password"
//                       name="view-password"
//                       onClick={() => {
//                         setViewPass(!viewPass);
//                       }}
//                     />
//                     <label for="view-password" class="checkbox-custom-label">
//                       View Password
//                     </label>
//                   </div>

//                   <div class="form-group">
//                     <button
//                       type="submit"
//                       disabled={loggedin}
//                       class="btn dark-2 btn-md full-width pop-login"
//                     >
//                       {!error ? (
//                         <>
//                           {loggedin ? (
//                             <>
//                               <span>Logging you in</span>
//                               &nbsp;
//                               <div className="login-loader"></div>
//                             </>
//                           ) : (
//                             "Login"
//                           )}
//                         </>
//                       ) : (
//                         <span>Try again !</span>
//                       )}
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//             <div class="modal-footer">
//               <div class="mf-link">
//                 <i class="ti-user"></i>Don't have an account?
//                 <Link className="btn btn-warning btn-sm ml-3" to="/signup">
//                   Sign Up
//                 </Link>
//               </div>
//               <div class="mf-forget">
//                 <Link to="/resetpassword">
//                   <i class="ti-help"></i>&nbsp;Forgot Password
//                 </Link>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
